<template>
  <div class="wrapper">
    
  </div>
</template>
<script>

export default {
  name: 'ExplosiveWeb',
  components: {
  },
  data() {
  }
}

window.location.replace("https://explosivebet.lajtkep.dev/");
</script>

<style scoped>
</style>